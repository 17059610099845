import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="content">
        (staging3) Coming soon... <span className="blink">|</span>
      </div>
    </div>
  );
}

export default App;
